import memoise from 'memoizee'

import { selectPromoMechanics } from 'src/modules/SpaceAssignment/store/reducer/promoMechanics'
import { selectSpaceInstanceById } from 'src/modules/SpaceAssignment/store/selectors'
import {
  selectProductImports,
  selectProducts,
  fetchProductBySKU,
  clearUploadedProductsData,
} from 'src/modules/NominationsUpload/store/index'
import { actionCreateShelfProduct } from '../../ShelfProductsDetail/reducer/shelfProduct'

import parseWorkbook from '../parseWorkbook'
import validateProduct from '../validate'

const UPDATE_PRODUCT = 'update_product'
const ADD_PRODUCTS = 'add_products'
const UPDATE_STATUS = 'update_status'
const RESET_IMPORTS = 'reset_imports'

const updateStatus = status => ({ type: UPDATE_STATUS, payload: status })
const addProducts = products => ({ type: ADD_PRODUCTS, payload: products })
const updateProduct = product => ({ type: UPDATE_PRODUCT, payload: product })
const resetImports = () => ({ type: RESET_IMPORTS })

const actionGetData = async (_, dispatch) => {
  dispatch(resetImports())
  dispatch(clearUploadedProductsData())
}

const lookupSku = sku => async (dispatch, getState) => {
  const trimmedSku = sku.trim().replaceAll(' ', '')
  await dispatch(fetchProductBySKU({ sku: trimmedSku }))
  return selectProducts(getState()).find(p => p.sku === trimmedSku)
}

const lookupPromoMechanic = promoMechanic => (dispatch, getState) => {
  if (!promoMechanic) return null
  return selectPromoMechanics(getState()).find(p => p.title === promoMechanic.trim())
}

const makeLookupCategory = shelves => (shelfId, categoryTitle) => {
  const shelf = shelves.find(s => s.id === shelfId)
  if (!shelf) return null

  const { categories } = shelf
  const category = categories.find(c => c.title === categoryTitle)
  return category && category.id
}

const makeLookupBusinessUnit = shelves => (shelfId, categoryTitle) => {
  const shelf = shelves.find(s => s.id === shelfId)
  if (!shelf) return null

  const { categories } = shelf
  const category = categories.find(c => c.title === categoryTitle)

  return category ? category.businessUnit : null
}

const importProducts = (data, instanceId, shelves, isLockedForChangeControl) => async (
  dispatch,
  getState
) => {
  const setStatus = status => dispatch(updateStatus(status))
  const lookupSkuCached = memoise(sku => dispatch(lookupSku(sku)), { promise: true })
  const spaceInstance = selectSpaceInstanceById(getState(), instanceId)
  const lookupPromoMechanicCached = memoise(
    promoMechanicValue => dispatch(lookupPromoMechanic(promoMechanicValue)),
    { promise: true }
  )
  const lookupCategory = makeLookupCategory(shelves)
  const lookupBusinessUnit = makeLookupBusinessUnit(shelves)

  try {
    setStatus('Processing nominations...')
    const products = parseWorkbook(data)
    dispatch(addProducts(products))

    setStatus('Validating nominations...')

    await Promise.all(
      products.map(async product => {
        const validatedProduct = await validateProduct(product, {
          lookupBusinessUnit,
          lookupCategory,
          lookupSku: lookupSkuCached,
          lookupPromoMechanic: lookupPromoMechanicCached,
          spaceInstance,
          isLockedForChangeControl,
        })
        dispatch(updateProduct(validatedProduct))
      })
    )

    setStatus('Nominations validated')
  } catch (error) {
    setStatus(`Error: ${error.message}`)
  }
}

const uploadProducts = () => async (dispatch, getState) => {
  const state = getState()
  const products = selectProductImports(state)
  const validProducts = products.filter(product => product.valid)

  dispatch(updateStatus({ message: 'Creating products...', isUploading: true }))

  for (let index = 0; index < validProducts.length; index += 1) {
    const product = validProducts[index]

    const productToCreate = {
      ...product,
      clusters: [],
    }

    if (productToCreate.salesPhaseWeeks !== null && productToCreate.salesPhaseWeeks !== undefined) {
      productToCreate.salesPhaseWeeks = productToCreate.salesPhaseWeeks.join()
    }

    // eslint-disable-next-line no-await-in-loop
    await dispatch(actionCreateShelfProduct(productToCreate))
  }

  dispatch(updateStatus({ message: 'Products created', isUploading: false, isFinished: true }))

  return products
}

export {
  UPDATE_PRODUCT,
  ADD_PRODUCTS,
  UPDATE_STATUS,
  RESET_IMPORTS,
  fetchProductBySKU,
  updateStatus,
  importProducts,
  uploadProducts,
  actionGetData,
}
