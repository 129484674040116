import { PROMO_MECHANIC_NOT_DEFINED_ID } from 'src/constants/shelfProductDetails'
import { enableHfssRestriction } from 'src/functions/productHfssStatus'

import { isToDecimalPlaces } from 'src/utils/validation'

const validate = values => {
  const {
    category,
    product: { error: productError, sku },
    promoMechanic,
    pricePoint,
    space,
  } = values

  const isToTwoDecimalPlaces = isToDecimalPlaces(2)
  const errors = {}

  const hfssValue = values.product.hfss === 'YES' ? 1 : 0
  const hfssError = enableHfssRestriction(space?.hfssFlag, hfssValue)

  if (!category) {
    errors.category = 'A category must be selected'
  }

  if (!promoMechanic) {
    errors.promoMechanic = 'A promo mechanic must be selected'
  }

  if (productError) {
    errors.product = { sku: productError }
  }

  if (hfssError) {
    errors.product = { sku: `HFSS SKU - ${sku} cannot be uploaded in a HFSS restricted space` }
  }

  if (promoMechanic !== PROMO_MECHANIC_NOT_DEFINED_ID && !pricePoint) {
    errors.pricePoint = 'A price point must be entered'
  }

  if (pricePoint && !isToTwoDecimalPlaces(pricePoint)) {
    errors.pricePoint = 'Price point can only have two decimal places'
  }

  return errors
}

export default validate
