import Api from 'src/api/Base'

class PostPsaPdfByStoreNumber extends Api {
  requestUrl = `/api/Psa/Cycle/${this.getParams().cycleId}/Store/${
    this.getParams().storeNumber
  }/isSegmentedVersion/${this.getParams().isSegmentedVersion}/DownloadSelectedPsaPdf`

  formatRequestData = params => params.s3Info

  method = 'POST'

  responseType = 'blob'
}

export default PostPsaPdfByStoreNumber
