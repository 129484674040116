import { createAsyncThunk } from '@reduxjs/toolkit'

import { handleCreateAsyncThunkResult, buildThunkPrefix } from 'src/utils/createAsyncThunkHandler'
import { PostPsaPdfByStoreNumber } from 'src/api/RangeAndSpace'

const NAMESPACE = 'PsaPdfByStoreNumberDetails'

const DownloadPsaPdfByStoreNumber = createAsyncThunk(
  buildThunkPrefix(NAMESPACE, 'DownloadPsaPdfByStoreNumber'),
  async (
    { storeNumber, cycleId, isSegmentedVersion, s3Key, s3Bucket },
    { getState, dispatch, rejectWithValue }
  ) => {
    const s3Info = { s3Bucket, s3Key }
    const store = getState()
    const postPsaPdfByStoreNumberInstance = new PostPsaPdfByStoreNumber(store, {
      params: { storeNumber, cycleId, isSegmentedVersion, s3Info },
    })

    const response = await handleCreateAsyncThunkResult(
      postPsaPdfByStoreNumberInstance,
      dispatch,
      rejectWithValue,
      false
    )
    return response
  }
)

// eslint-disable-next-line import/prefer-default-export
export { DownloadPsaPdfByStoreNumber }
