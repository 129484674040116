import { createSelector } from 'reselect'

import sortArray from 'src/utils/sortArray'

import { buildReducer, bindSelector } from 'src/store/modules'

import { fetchProductBySKU as getProductBySKU } from 'src/store/modules/asyncThunks/shelfProduct'

import { createAction, createSlice } from '@reduxjs/toolkit'
import reducer from './reducer'

const UI_KEY = 'ui'
const NAMESPACE = 'NominationsUpload'
const STORE_PATH = `modules/${NAMESPACE}`

const selectModule = state => state.modules.NominationsUpload
const selectProducts = createSelector(selectModule, module => module.products || [])
const selectUi = bindSelector(NAMESPACE)(state => state[UI_KEY])
const selectProductImports = createSelector(selectUi, data =>
  sortArray(Object.values(data.imports), 'rowOrder')
)
const selectStatus = createSelector(selectUi, selectProductImports, (ui, products) => ({
  ...ui.status,
  isValid: !(
    products.length === 0 ||
    products.some(p => !p.valid) ||
    products.some(p => p.errors?.length >= 1)
  ),
}))

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchProductBySKU = getProductBySKU(NAMESPACE)

const nominationProductDetailsSlice = createSlice({
  name: NAMESPACE,
  initialState: [],
  reducers: {
    clearUploadedProductsData: () => {
      return []
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProductBySKU.fulfilled, (state, action) => {
      return [...(state || []), action.payload]
    })
    builder.addCase(fetchProductBySKU.rejected, (state, action) => {
      const { sku } = action?.meta.arg
      const errorMessage = action.payload.data.detail
      return [...(state || []), { sku, errorMessage }]
    })
  },
})

export default buildReducer(NAMESPACE, {
  [UI_KEY]: reducer,
  products: nominationProductDetailsSlice.reducer,
})
export const { clearUploadedProductsData } = nominationProductDetailsSlice.actions
export { selectProducts, selectProductImports, selectStatus, actionClearData, fetchProductBySKU }
