import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { connect } from 'react-redux'

import { Card, Heading3, TextButton } from '@jsluna/react'

import {
  SSPSA_FOOD_VERSION_MESSAGES,
  SSPSA_FOOD_VERSION_NO_SSPSA_GENERATED_MESSAGES,
} from 'src/constants/sspsaFoodVersion'

import {
  SSPSA_NONFOOD_VERSION_MESSAGES,
  SSPSA_NONFOOD_VERSION_NO_SSPSA_GENERATED_MESSAGES,
} from 'src/constants/sspsaNonFoodVersion'

import capitalise from 'src/utils/capitalise'
import { DATE_ABBREVIATED, DATETIME_FORMAT } from 'src/utils/datePeriod'

import Section from 'src/components/Section'

import withExport from 'src/hoc/withExport'
import { DownloadPsaPdfByStoreNumber } from '../store/downloadPsaPdfByStoreNumber'

const DownloadButton = withExport(TextButton)

const formatDateTitle = date => moment(date).format(DATE_ABBREVIATED)
const formatDate = date => moment(date).format(DATETIME_FORMAT)
const getWeekNumber = date => {
  const initialDate = new Date('0001-01-01')
  const startDate = new Date('2023-03-05')
  const currentDate = new Date(date)
  const differenceInStartTime = startDate.getTime() - initialDate.getTime()
  const differenceStartInDays = Math.round(differenceInStartTime / (1000 * 3600 * 24))

  const differenceInEffectiveTime = currentDate.getTime() - initialDate.getTime()
  const differenceEffectiveInDays = Math.round(differenceInEffectiveTime / (1000 * 3600 * 24))
  const weekCount = Math.floor((differenceEffectiveInDays - differenceStartInDays) / 7)

  return (weekCount % 52) + 1
}

const getEndDate = (effectiveDate, psaEndDate, endDate) => {
  if (psaEndDate != null) {
    return moment(psaEndDate)
  }
  if (endDate != null) {
    return moment(endDate)
  }
  return moment(effectiveDate).add(6, 'days')
}

const getLinkTitle = ({ title, startDate, endDate, businessUnit }, generatedSspsa) => {
  const effectiveDate = generatedSspsa?.effectiveDate
  const psaEndDate = generatedSspsa?.endDate
  if (businessUnit === 'food' && startDate >= '2023-01-25' && effectiveDate)
    return `Week ${getWeekNumber(effectiveDate)} (${formatDateTitle(
      effectiveDate
    )} - ${formatDateTitle(getEndDate(startDate, psaEndDate, endDate))})`
  return `${title} (${formatDateTitle(startDate)} - ${formatDateTitle(
    getEndDate(startDate, psaEndDate, endDate)
  )})`
}

const getMessage = (messages = []) => (type = '', name = '') =>
  messages.find(message => message.name === `${type}-range-and-space-${name}`)

const getDefaultMessage = (type, version, areSspsaGeneratedForThisPeriod) => {
  let title = ''
  if (type === 'food') {
    title = areSspsaGeneratedForThisPeriod
      ? SSPSA_FOOD_VERSION_NO_SSPSA_GENERATED_MESSAGES[version]
      : SSPSA_FOOD_VERSION_MESSAGES[version]
  } else if (type === 'gm') {
    title = areSspsaGeneratedForThisPeriod
      ? SSPSA_NONFOOD_VERSION_NO_SSPSA_GENERATED_MESSAGES[version]
      : SSPSA_NONFOOD_VERSION_MESSAGES[version]
  }
  return title
}

const formatLink = message => {
  const regex = new RegExp(
    '(?:http(s?)://?([^.s]+)?[^.s]+.[^s]+)(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:([-A-Z0-9+&@#/%=~_|$?!:,.]*)|[A-Z0-9+&@#/%=~_|$])',
    'ig'
  )

  const newMessage = message.replace(regex, '<a target="_blank" href="$&">$&</a>')

  return newMessage !== message ? (
    // eslint-disable-next-line react/no-danger
    <span dangerouslySetInnerHTML={{ __html: newMessage }} />
  ) : (
    message
  )
}

export const Component = ({
  sspsas,
  version,
  messages,
  downloadSSPSA,
  downloadFullVersionSSPSA,
  type,
}) => {
  const available =
    sspsas?.filter(
      sspsa => sspsa?.areSspsaGeneratedForThisPeriod && sspsa.generatedSspsas?.length > 0
    ).length > 0
  const getVersionMessage = getMessage(messages)
  const configuredMessage = getVersionMessage(type, version)
  const defaultMessage = getDefaultMessage(
    type,
    version,
    sspsas?.some(sspsa => sspsa?.areSspsaGeneratedForThisPeriod)
  )
  const message = configuredMessage?.enabled
    ? configuredMessage.value || defaultMessage
    : defaultMessage

  return (
    <Section className="ln-u-hard-bottom">
      <Card className="ln-u-hard-bottom">
        <Heading3>{(available || message) && capitalise(version)}</Heading3>
        {sspsas?.map(sspsa => {
          const generatedSspsa =
            sspsa?.generatedSspsas?.length > 0 &&
            (sspsa?.generatedSspsas.find(
              generatedPsa => generatedPsa.isSegmentedVersion === true
            ) ||
              sspsa?.generatedSspsas[0])

          const generatedSspsaFullVersion =
            sspsa?.generatedSspsas?.length > 1 &&
            sspsa?.generatedSspsas.find(generatedPsa => generatedPsa.isSegmentedVersion === false)

          return (
            available && (
              <>
                <DownloadButton
                  getExportsData={() => downloadSSPSA(generatedSspsa)}
                  data-control="sspsa-download"
                  className="ln-u-hard-top ln-u-soft-left"
                >
                  {getLinkTitle(sspsa, generatedSspsa)}
                  {sspsa?.businessUnit === 'food' &&
                    generatedSspsaFullVersion &&
                    ' - Changes for this week only'}
                </DownloadButton>
                <br />
                {sspsa?.businessUnit === 'food' && generatedSspsaFullVersion && (
                  <DownloadButton
                    getExportsData={() => downloadFullVersionSSPSA(generatedSspsaFullVersion)}
                    data-control="sspsa-download"
                    className="ln-u-hard-top ln-u-soft-left ln-full-version"
                  >
                    {getLinkTitle(sspsa, generatedSspsaFullVersion)} - Changes for this week plus
                    all live current spaces
                  </DownloadButton>
                )}
                {generatedSspsa.generatedAt && (
                  <p className="ln-u-soft-left" data-control="sspsa-last-updated">
                    Last updated {formatDate(generatedSspsa.generatedAt)}
                  </p>
                )}
              </>
            )
          )
        })}

        {!available && message && (
          <p className="ln-u-soft-left" data-control={`sspsa-error-message-${version}`}>
            {formatLink(message)}
          </p>
        )}
      </Card>
    </Section>
  )
}

Component.propTypes = {
  sspsas: PropTypes.arrayOf(
    PropTypes.shape({
      generatedSspsas: PropTypes.arrayOf(
        PropTypes.shape({
          generatedAt: PropTypes.string,
          cycleId: PropTypes.string,
        })
      ),
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      businessUnit: PropTypes.string,
      title: PropTypes.string.isRequired,
      areSspsaGeneratedForThisPeriod: PropTypes.bool.isRequired,
    })
  ),
  version: PropTypes.string,
  downloadSSPSA: PropTypes.func,
  downloadFullVersionSSPSA: PropTypes.func,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  type: PropTypes.string,
}

Component.defaultProps = {
  sspsas: null,
  version: null,
  downloadSSPSA: () => {},
  downloadFullVersionSSPSA: () => {},
  messages: [],
  type: '',
}

const mapDispatchToProps = (dispatch, { storeNumber }) => ({
  downloadSSPSA: generatedSspsa => {
    return dispatch(
      DownloadPsaPdfByStoreNumber({
        storeNumber,
        cycleId: generatedSspsa?.cycleId,
        isSegmentedVersion: !!generatedSspsa?.isSegmentedVersion,
        s3Key: generatedSspsa.s3Key,
        s3Bucket: generatedSspsa.s3Bucket,
      })
    )
  },
  downloadFullVersionSSPSA: generatedSspsaFullVersion =>
    dispatch(
      DownloadPsaPdfByStoreNumber({
        storeNumber,
        cycleId: generatedSspsaFullVersion?.cycleId,
        isSegmentedVersion: !!generatedSspsaFullVersion?.isSegmentedVersion,
        s3Key: generatedSspsaFullVersion.s3Key,
        s3Bucket: generatedSspsaFullVersion.s3Bucket,
      })
    ),
})

export default connect(null, mapDispatchToProps)(Component)
