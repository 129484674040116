import { createAction, createSlice } from '@reduxjs/toolkit'
import {
  fullFilledDataHandler,
  pendingDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

import { createSelector } from 'reselect'
import {
  fetchProductBySKU as getProductBySKU,
  productRejectedDataHandler,
} from 'src/store/modules/asyncThunks/shelfProduct'

const NAMESPACE = 'ProductSearch'
const STORE_PATH = `modules/${NAMESPACE}`

const selectModule = state => state.modules.ProductSearch
const selectProductDetail = createSelector(selectModule, module => module)

const actionClearData = createAction(`${STORE_PATH}/CLEAR_DATA`)

const fetchProductBySKU = getProductBySKU(NAMESPACE)

const productBySKUSlice = createSlice({
  name: NAMESPACE,
  initialState: { ...initialState, data: {} },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProductBySKU.pending, pendingDataHandler)
    builder.addCase(fetchProductBySKU.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchProductBySKU.rejected, productRejectedDataHandler)
  },
})

const actionGetProductsForSku = sku => async (dispatch, getState) => {
  await dispatch(fetchProductBySKU({ sku }))
  const product = selectProductDetail(getState())
  return product
}

export default { [NAMESPACE]: productBySKUSlice.reducer }
export { actionGetProductsForSku, fetchProductBySKU, productBySKUSlice, actionClearData }
