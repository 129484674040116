import { createSelector } from 'reselect'

import {
  fetchProductBySKU as getProductBySKU,
  productRejectedDataHandler,
} from 'src/store/modules/asyncThunks/shelfProduct'
import { createSlice } from '@reduxjs/toolkit'
import {
  fullFilledDataHandler,
  pendingDataHandler,
  initialState,
} from 'src/utils/createAsyncThunkHandler'

const NAMESPACE = 'InlineProductSearch'

const selectModule = state => state.modules.InlineProductSearch
const selectProductDetails = createSelector(selectModule, module => module)

const fetchProductBySKU = getProductBySKU(NAMESPACE)

const productBySKUSlice = createSlice({
  name: NAMESPACE,
  initialState: { ...initialState, data: {} },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchProductBySKU.pending, pendingDataHandler)
    builder.addCase(fetchProductBySKU.fulfilled, fullFilledDataHandler)
    builder.addCase(fetchProductBySKU.rejected, productRejectedDataHandler)
  },
})

const actionGetProductForSku = sku => async (dispatch, getState) => {
  await dispatch(fetchProductBySKU({ sku }))
  const product = selectProductDetails(getState())
  return product
}
export default { [NAMESPACE]: productBySKUSlice.reducer }

export { actionGetProductForSku }
