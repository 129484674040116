import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  GridWrapper,
  GridItem,
  FlagWrapper,
  FlagBody,
  FlagComponent,
  ProgressSpinner,
  TextInputField,
  FilledButton,
} from '@jsluna/react'

import { actionGetProductsForSku, actionClearData } from './store'

export class ProductSearchComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sku: props.field.value.sku || '',
      searching: false,
      hasSearched: false,
      product: {},
    }

    this.onChangeSku = this.onChangeSku.bind(this)
    this.search = this.search.bind(this)
    this.onSelectProduct = this.onSelectProduct.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.target.blur()
      this.search(event)
    }
  }

  onChangeSku(event) {
    const { value } = event.target
    const { hasSearched } = this.state

    this.setState({ sku: value })

    if (hasSearched) {
      this.setState({
        hasSearched: false,
      })
    }
  }

  onSelectProduct(product) {
    const {
      form: { setFieldValue },
      field: { name },
      clearResults,
    } = this.props

    setFieldValue(name, product)
    clearResults()

    this.setState({
      hasSearched: false,
    })
  }

  getError() {
    const { hasSearched, error } = this.state
    const {
      form: { errors, touched },
      field: { name },
    } = this.props

    if (touched[name]) {
      return errors[name]
    }

    if (hasSearched && error) {
      return error.data.detail
    }

    return null
  }

  async search(event) {
    event.preventDefault()

    const { getProductsForSku } = this.props
    const { sku } = this.state

    this.setState({ searching: true })
    const { data: product, error } = await getProductsForSku(sku)

    this.setState({
      searching: false,
      hasSearched: true,
      product,
      error,
    })
  }

  render() {
    const { sku, searching, product, hasSearched } = this.state
    const {
      field: { name },
      disabled,
    } = this.props
    const showResults = hasSearched && product?.sku != null

    return (
      <div>
        <FlagWrapper className="ln-u-push-bottom">
          <FlagBody alignment="top">
            <div onKeyPress={this.handleKeyPress} role="presentation">
              <TextInputField
                label="SKU"
                name={name}
                value={sku}
                onChange={this.onChangeSku}
                className="ln-u-flush-bottom"
                error={this.getError()}
                validationFirst={false}
                disabled={disabled}
              />
            </div>
          </FlagBody>
          <FlagComponent alignment="top" className="ln-u-push-top-lg">
            <div data-control="search-sku">
              <FilledButton
                color="dark"
                onClick={this.search}
                className="ln-u-push-left c-search-button"
                disabled={disabled}
              >
                {searching ? (
                  <ProgressSpinner className="u-flush-right" color="light" size="small" />
                ) : (
                  'Search'
                )}
              </FilledButton>
            </div>
          </FlagComponent>
        </FlagWrapper>
        <div data-control="product-results">
          <GridWrapper matrix>
            {showResults && (
              <GridItem key={product.id} size="1/1">
                <div data-control="product-result" data-sku={product.sku}>
                  <FilledButton color="dark" onClick={() => this.onSelectProduct(product)}>
                    {`${product.sku} - ${product.description}`}
                  </FilledButton>
                </div>
              </GridItem>
            )}
          </GridWrapper>
        </div>
      </div>
    )
  }
}

ProductSearchComponent.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      sku: PropTypes.string,
    }).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }).isRequired,
  disabled: PropTypes.bool.isRequired,
  getProductsForSku: PropTypes.func.isRequired,
  clearResults: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
  getProductsForSku: actionGetProductsForSku,
  clearResults: actionClearData,
}

export default connect(null, mapDispatchToProps)(ProductSearchComponent)
